import React from "react";

const cards = [
  {
    title: "Обучение на реальных данных",
    description:
      "Наша модель непрерывно обучается на актуальных данных, что позволяет ей улучшаться и предоставлять более точные и актуальные рекомендации.",
  },
  {
    title: "Поддержка комплексного анализа",
    description:
      "AI Molodca предлагает возможность глубокой аналитики, включая визуализацию данных и детальные отчеты, что упрощает выявление ключевых факторов, влияющих на успеваемость студентов.",
  },
  {
    title: "Снижение затрат",
    description:
      "Благодаря точным предсказаниям и оптимальному распределению ресурсов, наша модель способствует снижению финансовых затрат образовательного учреждения.",
  },
  {
    title: "Удобство использования",
    description:
      "Интерфейс модели интуитивно понятен и прост в использовании как для специалистов, так и для новичков, что минимизирует время на обучение и адаптацию.",
  },
];

export const AboutSection3: React.FC = () => {
  return (
    <div className="flex flex-col 2xl:flex-row gap-6 lg:gap-10 py-12 lg:py-20 w-full">
      <div className="relative rounded-[2.5rem] overflow-hidden flex-1 shrink-0 z-0 text-white p-7 py-12 font-medium flex flex-col gap-12">
        <img
          src="/green-ai-bg.jpg"
          className="absolute top-0 left-0 z-[-1] h-full w-full opacity-50"
          alt="AI Molodca"
        />
        <h2 className="z-1 text-[4rem]">
          Прогнозирование вероятности отчисления
        </h2>
        <p className="z-1 text-[2.5rem]">
          Наша модель помогает выявить студентов, которым требуется
          дополнительная поддержка, тем самым способствуя снижению количества
          отчислений и улучшению качества обучения. Мы анализируем данные
          успеваемости, посещаемости и другие факторы, чтобы предоставить
          персонализированные данные для каждого студента.
        </p>
      </div>

      <div className="flex-1 shrink-0 flex flex-wrap gap-6 lg:gap-10">
        {cards.map((card) => (
          <div
            key={card.title}
            className="text-white tex-medium flex flex-col gap-6 p-6 w-full lg:w-[calc(50%-20px)] bg-bg-quaternary rounded-[40px]"
          >
            <h3 className="text-[2rem]">{card.title}</h3>
            <p className="text-[1.5rem]">{card.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
