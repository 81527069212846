import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tabs } from "@consta/uikit/Tabs";
import { useTabsContext } from "../context/Navigation";
import "./Header.css";

const routes = [
  { path: "/", type: "marks", name: "Оценки" },
  { path: "/diploma", type: "diploma", name: "Диплом" },
  { path: "/expulsion", type: "expulsion", name: "Отчисление" },
];

const getItemLabel = (item: (typeof routes)[0]) => item.name;

export const Header: React.FC = () => {
  const { activeTab, setActiveTab } = useTabsContext();

  const navigate = useNavigate();

  const value = routes?.find((item) => item.type === activeTab);

  const onChange = (newItem: (typeof routes)[0]) => {
    setActiveTab(newItem.type as never);
    navigate(newItem.path);
  };

  return (
    <header className="bg-bg-ternary bg-opacity-50 rounded-[2rem] p-4 flex flex-col gap-8 2 2xl:flex-row justify-between items-center overflow-hidden w-full">
      <Link
        className="text-[6rem] font-medium text-white relative rounded-[2.5rem] px-3 overflow-hidden z-0 shrink-0"
        to="/"
        onClick={() => setActiveTab("marks")}
      >
        <img
          src="/green-ai-bg.jpg"
          className="absolute top-0 left-0 z-[-1]"
          alt="AI Molodca"
        />
        <h1 className="z-1">AI Molodca</h1>
      </Link>

      <Tabs
        className="w-full lg:w-fit text-[3rem]"
        value={value}
        onChange={onChange}
        items={routes}
        getItemLabel={getItemLabel}
        view="clear"
        fitMode="scroll"
      />
    </header>
  );
};
