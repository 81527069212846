import React, { useEffect, useState } from "react";
import { Button } from "@consta/uikit/Button";
import { Combobox } from "@consta/uikit/Combobox";
import { RadioGroup } from "@consta/uikit/RadioGroup";
import { DatePicker } from "@consta/uikit/DatePicker";
import { CollapseGroup } from "@consta/uikit/CollapseGroup";
import { Table } from "@consta/uikit/Table";
import { TextField } from "@consta/uikit/TextField";
import { Select } from "@consta/uikit/Select";
import { AppLine } from "../../../components/AppLine";
import "./SingleForm.css";

type Props = {};

const subjects = [
  "Информатика и ИКТ",
  "Математика",
  "Русский язык",
  "Обществознание",
  "Физика",
  "Химия",
  "Английский язык",
  "История",
  "Иностранный язык",
  "Французский язык",
  "Немецкий язык",
].map((item) => ({ label: item, id: item }));

export const getCoordinatesFromAddress = async (address: string) => {
  const apiKey = "062811fc-514d-47d7-b209-b289a2eb1d00";
  const url = `https://geocode-maps.yandex.ru/1.x/?apikey=${apiKey}&geocode=${encodeURIComponent(
    address
  )}&format=json`;

  try {
    const response = await fetch(url);
    const data = await response.json();
    const point =
      data.response.GeoObjectCollection.featureMember[0]?.GeoObject?.Point?.pos;
    if (point) {
      const [longitude, latitude] = point.split(" ");
      return { latitude, longitude };
    }
  } catch (error) {
    console.error("Ошибка при получении координат:", error);
  }
  return null;
};

function* getIdGenerator() {
  let id = 1;
  while (true) {
    yield id++;
  }
}

const idGenerator = getIdGenerator();

const getGroupIndex = async () => {
  const disciplines: any = await fetch(
    "https://students.dev.echo-company.ru/api/v1/constants/disciplines-groups/full"
  )
    .then((response) => response.json())
    .catch((error) => console.error(error));

  const keys = Object.keys(disciplines);

  const map: any = {};

  Object.entries(disciplines).forEach(([key, value]: any) => {
    const val = key.concat(
      Array.from(value).join(" ") ||
        Array.from(
          new Set(
            Array.from(value)
              .join(" ")
              .split(" ")
              .map((i) => i.toLowerCase())
          )
        ).join(" ")
    );
    map[key] = val;
  });

  return {
    disciplines,
    callback: async (discipline: string): Promise<number> => {
      if (!discipline) {
        return 0;
      }

      try {
        const response = await fetch(
          "https://students.dev.echo-company.ru/api/v1/predict-mass/disciplines",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify([discipline]),
          }
        );
        const data = await response.json();
        return keys.findIndex((item) => item === data[0]);
      } catch (error) {
        console.error("Ошибка при предсказании дисциплин:", error);
        return 0;
      }
    },
  };
};

export const SingleForm = (props: Props) => {
  const [values, setValues] = useState<any>({
    ege: [{ id: idGenerator.next().value, subject: "", mark: "" }],
    marks: Array.from({ length: 5 }, (_, x) =>
      Array.from({ length: 2 }, (_, y) => ({
        label: `Семестр ${x * 2 + y + 1}`,
        dolg: "",
        marks: [
          {
            id: idGenerator.next().value,
            subject: "",
            mark: "",
            subject_group: "",
          },
        ],
      }))
    ).flat(1),
  });

  const [result, setResult] = useState<
    {
      id: string;
      percent: number;
      mark: number;
      percentAVG: number;
      markAVG: number;
      percentOptimistic: number;
      markOptimistic: number;
    }[]
  >([]);

  const [groupIndexGetter, setGroupIndexGetter] = useState<{
    callback: (d: string) => Promise<number>;
  }>();

  const [disciplines, setDisciplines] = useState<Record<string, unknown>>({});

  useEffect(() => {
    getGroupIndex().then((res) => {
      setGroupIndexGetter({ callback: res.callback });
      setDisciplines(res.disciplines);
    });
  }, []);

  const uniqueEge = values.ege.filter(
    (item: any, index: any, self: any) =>
      index === self.findIndex((t: any) => t.subject === item.subject)
  );

  const keys = Object.keys(disciplines);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setResult([]);
    const firstDay = new Date(values.firstDay);
    const firstStudyDay = new Date(values.firstStudyDay);

    const marksByGroups = await Promise.all(
      values.marks.map(async (item: any) => {
        const marksByIndexes = await item.marks.reduce(
          async (accPromise: Promise<any>, cur: any) => {
            const acc = await accPromise;
            const indexByGroup = cur.subject_group
              ? keys.findIndex((key) => key === cur.subject_group)
              : -1;
            const index = ~indexByGroup
              ? indexByGroup
              : (await groupIndexGetter?.callback?.(cur.subject)) || 0;

            const mark = Math.max(acc[index] || 0, Number(cur.mark?.id || 0));

            acc[index] = mark;
            return acc;
          },
          Promise.resolve(Object.create(null))
        );

        return marksByIndexes;
      })
    );

    let coords: { latitude?: string; longitude?: string } = {};

    if (values.address) {
      const addressCoordinates = await getCoordinatesFromAddress(
        values.address
      ).catch(console.log);
      if (addressCoordinates) {
        coords = addressCoordinates;
      }
    }

    const marks = values.marks.map((item: any) => ({
      ...item,
      marks: item.marks.filter((item: any) => Boolean(item.mark)),
    }));

    let result = Array.from({ length: 24 }, (_, i) => ({
      Координаты_lat: Number(coords.latitude) || undefined,
      Координаты_lon: Number(coords.longitude) || undefined,

      Бакалавриат: values.academicLevel?.name
        ? values.academicLevel?.name === "Бакалавр"
        : undefined,
      ...(Boolean(values.army) && {
        Военный_билет: values.army?.name === "Да",
      }),
      ...(Boolean(values.years) && { Возраст: Number(values.years?.id) }),
      Год_обучения: Number(values.course?.id) || 1,
      ...(Boolean(values.firstDay) && {
        Дата_зачисления_день: firstDay.getDate(),
        Дата_зачисления_месяц: firstDay.getMonth() + 1,
      }),
      ...(Boolean(values.firstStudyDay) && {
        Дата_начала_обучения_день: firstStudyDay.getDate(),
        Дата_начала_обучения_месяц: firstStudyDay.getMonth() + 1,
      }),
      ЕГЭ_английский_язык:
        Number(
          uniqueEge.find((item: any) => item.subject === "Английский язык")
            ?.mark?.id
        ) || undefined,
      ЕГЭ_иностранный_язык:
        Number(
          uniqueEge.find((item: any) => item.subject === "Иностранный язык")
            ?.mark?.id
        ) || undefined,
      ЕГЭ_информатика_и_икт:
        Number(
          uniqueEge.find((item: any) => item.subject === "Информатика и ИКТ")
            ?.mark?.id
        ) || undefined,
      ЕГЭ_история:
        Number(
          uniqueEge.find((item: any) => item.subject === "История")?.mark?.id
        ) || undefined,
      ЕГЭ_математика:
        Number(
          uniqueEge.find((item: any) => item.subject === "Математика")?.mark?.id
        ) || undefined,
      ЕГЭ_немецкий_язык:
        Number(
          uniqueEge.find((item: any) => item.subject === "Немецкий язык")?.mark
            ?.id
        ) || undefined,
      ЕГЭ_обществознание:
        Number(
          uniqueEge.find((item: any) => item.subject === "Обществознание")?.mark
            ?.id
        ) || undefined,
      ЕГЭ_русский_язык:
        Number(
          uniqueEge.find((item: any) => item.subject === "Русский язык")?.mark
            ?.id
        ) || undefined,
      ЕГЭ_физика:
        Number(
          uniqueEge.find((item: any) => item.subject === "Физика")?.mark?.id
        ) || undefined,
      ЕГЭ_французский_язык:
        Number(
          uniqueEge.find((item: any) => item.subject === "Французский язык")
            ?.mark?.id
        ) || undefined,
      ЕГЭ_химия:
        Number(
          uniqueEge.find((item: any) => item.subject === "Химия")?.mark?.id
        ) || undefined,
      Из_России: values.fromRussia?.name
        ? values.fromRussia?.name === "Да"
        : undefined,
      ...(Boolean(values.disciplinesCountFuture) && {
        Количество_дисциплин_в_семестре: Number(
          values.disciplinesCountFuture?.id
        ),
      }),

      Школа_с_уклоном: values.school?.name
        ? values.school?.name === "Да"
        : undefined,
      Колледж: values.college?.name ? values.college?.name === "Да" : undefined,
      Льгота: values.bonuses?.name ? values.bonuses?.name === "Да" : undefined,
      Магистратура: values.academicLevel?.name
        ? values.academicLevel?.name === "Магистр"
        : undefined,
      Пол: values.sex?.name !== "Мужской",
      Полугодие: Number(values.semester?.id || 1),
      Практики: values.practicesCount?.id
        ? Number(values.practicesCount?.id || 0)
        : undefined,
      Призер_олимпиад: values.olimpic?.name
        ? values.olimpic?.name === "Да"
        : undefined,
      Проживание: values.studHome?.id
        ? [1, 2].includes(values.studHome?.id)
        : undefined,
      Покинул_общежитие: values.studHome?.id
        ? [2].includes(values.studHome?.id)
        : undefined,
      Специалитет: values.academicLevel?.name
        ? values.academicLevel?.name === "Специалитет"
        : undefined,
      Стипендия: values.money?.name ? values.money?.name === "Да" : undefined,
      Форма_обучения: values.studForm?.name
        ? values.studForm?.name === "Очная"
        : undefined,
      Количество_дисциплин_1_1: marks[0]?.marks?.length
        ? Number(marks[0].marks?.length || 0)
        : undefined,
      Количество_дисциплин_1_2: marks[1]?.marks?.length
        ? Number(marks[1].marks?.length || 0)
        : undefined,
      Количество_дисциплин_2_1: marks[2]?.marks?.length
        ? Number(marks[2].marks?.length || 0)
        : undefined,
      Количество_дисциплин_2_2: marks[3]?.marks?.length
        ? Number(marks[3].marks?.length || 0)
        : undefined,
      Количество_дисциплин_3_1: marks[4]?.marks?.length
        ? Number(marks[4].marks?.length || 0)
        : undefined,
      Количество_дисциплин_3_2: marks[5]?.marks?.length
        ? Number(marks[5].marks?.length || 0)
        : undefined,
      Количество_дисциплин_4_1: marks[6]?.marks?.length
        ? Number(marks[6].marks?.length || 0)
        : undefined,
      Количество_дисциплин_4_2: marks[7]?.marks?.length
        ? Number(marks[7].marks?.length || 0)
        : undefined,
      Количество_дисциплин_5_1: marks[8]?.marks?.length
        ? Number(marks[8].marks?.length || 0)
        : undefined,
      Количество_дисциплин_5_2: marks[9]?.marks?.length
        ? Number(marks[9].marks?.length || 0)
        : undefined,
      Количество_долгов_1_1: values.marks[0].dolg?.id
        ? Number(values.marks[0].dolg?.id || 0)
        : undefined,
      Количество_долгов_1_2: values.marks[1].dolg?.id
        ? Number(values.marks[1].dolg?.id || 0)
        : undefined,
      Количество_долгов_2_1: values.marks[2].dolg?.id
        ? Number(values.marks[2].dolg?.id || 0)
        : undefined,
      Количество_долгов_2_2: values.marks[3].dolg?.id
        ? Number(values.marks[3].dolg?.id || 0)
        : undefined,
      Количество_долгов_3_1: values.marks[4].dolg?.id
        ? Number(values.marks[4].dolg?.id || 0)
        : undefined,
      Количество_долгов_3_2: values.marks[5].dolg?.id
        ? Number(values.marks[5].dolg?.id || 0)
        : undefined,
      Количество_долгов_4_1: values.marks[6].dolg?.id
        ? Number(values.marks[6].dolg?.id || 0)
        : undefined,
      Количество_долгов_4_2: values.marks[7].dolg?.id
        ? Number(values.marks[7].dolg?.id || 0)
        : undefined,
      Количество_долгов_5_1: values.marks[8].dolg?.id
        ? Number(values.marks[8].dolg?.id || 0)
        : undefined,
      Количество_долгов_5_2: values.marks[9].dolg?.id
        ? Number(values.marks[9].dolg?.id || 0)
        : undefined,

      Индекс_группы_дисциплин: i,
      Оценка_1_1: marksByGroups[0][i]
        ? Number(marksByGroups[0][i] || 0)
        : undefined,
      Оценка_1_2: marksByGroups[1][i]
        ? Number(marksByGroups[1][i] || 0)
        : undefined,
      Оценка_2_1: marksByGroups[2][i]
        ? Number(marksByGroups[2][i] || 0)
        : undefined,
      Оценка_2_2: marksByGroups[3][i]
        ? Number(marksByGroups[3][i] || 0)
        : undefined,
      Оценка_3_1: marksByGroups[4][i]
        ? Number(marksByGroups[4][i] || 0)
        : undefined,
      Оценка_3_2: marksByGroups[5][i]
        ? Number(marksByGroups[5][i] || 0)
        : undefined,
      Оценка_4_1: marksByGroups[6][i]
        ? Number(marksByGroups[6][i] || 0)
        : undefined,
      Оценка_4_2: marksByGroups[7][i]
        ? Number(marksByGroups[7][i] || 0)
        : undefined,
      Оценка_5_1: marksByGroups[8][i]
        ? Number(marksByGroups[8][i] || 0)
        : undefined,
      Оценка_5_2: marksByGroups[9][i]
        ? Number(marksByGroups[9][i] || 0)
        : undefined,
    }));

    if (values.disciplines?.length) {
      const includedIds = values.disciplines?.map((i: any) => Number(i.id));
      result = result.filter((item) =>
        includedIds.includes(item.Индекс_группы_дисциплин)
      );
    }

    const indexes = Object.keys(disciplines);

    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify(result);

    fetch("https://students.dev.echo-company.ru/api/v1/predict-mass/marks", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((response) =>
        setResult(
          response?.message?.map((item: number, i: number) => {
            return {
              markOptimistic: Math.max(
                Math.round(response?.message_optimist[i] || 0),
                2
              ),
              percentOptimistic: `${
                response.percent_optimist[i].toFixed(2) || 0
              }%`,
              markAVG: Math.max(Math.round(response?.message_avg[i] || 0), 2),
              percentAVG: `${response.percent_avg[i].toFixed(2) || 0}%`,
              mark: Math.max(Math.round(item || 0), 2),
              percent: `${response.percents[i].toFixed(2) || 0}%`,
              id: indexes[i],
            };
          })
        )
      )
      .catch((error) => console.error(error));
  };

  const preparedMarks = values.marks
    .slice(0, (values.course?.id - 1) * 2 + values.semester?.id - 1)
    .map((item: any) => ({
      ...item,
      marks: item.marks.map((child: any, i: any) => (
        <div className="flex flex-col gap-6">
          <div
            className="flex lg:items-center flex-wrap lg:flex-nowrap max-lg:flex-col w-full justify-between gap-6"
            key={`${item.label}${i}`}
          >
            <TextField
              value={child.subject}
              className="shrink-0 flex-1"
              labelPosition="left"
              onBlur={() => {
                groupIndexGetter?.callback?.(child.subject).then((res: any) => {
                  setValues((v: any) => ({
                    ...v,
                    marks: v.marks.map((innerItem: any) => {
                      if (innerItem.label === item.label) {
                        return {
                          ...innerItem,
                          marks: innerItem.marks.map((inInnerItem: any) => {
                            if (inInnerItem.id === child.id) {
                              return {
                                ...inInnerItem,

                                subject_group: {
                                  label: keys[res],
                                  value: keys[res],
                                },
                              };
                            }

                            return inInnerItem;
                          }),
                        };
                      }

                      return innerItem;
                    }),
                  }));
                });
              }}
              onChange={(value) =>
                setValues((v: any) => ({
                  ...v,
                  marks: v.marks.map((innerItem: any) => {
                    if (innerItem.label === item.label) {
                      return {
                        ...innerItem,
                        marks: innerItem.marks.map((inInnerItem: any) => {
                          if (inInnerItem.id === child.id) {
                            return {
                              ...inInnerItem,
                              subject: value || "",
                            };
                          }

                          return inInnerItem;
                        }),
                      };
                    }

                    return innerItem;
                  }),
                }))
              }
              type="text"
              size="l"
              label={`${i + 1}. Название предмета:`}
              placeholder="Введите предмет"
            />

            <Select
              label="Оценка:"
              placeholder="5"
              className="shrink-0 w-[200px] small-label"
              labelPosition="left"
              items={Array.from({ length: 5 }, (_, i) => ({
                label: String(i + 1),
                id: String(i + 1),
              }))}
              value={child.mark}
              onChange={(value) =>
                setValues((v: any) => ({
                  ...v,
                  marks: v.marks.map((innerItem: any) => {
                    if (innerItem.label === item.label) {
                      return {
                        ...innerItem,
                        marks: innerItem.marks.map((inInnerItem: any) => {
                          if (inInnerItem.id === child.id) {
                            return {
                              ...inInnerItem,
                              mark: value,
                            };
                          }

                          return inInnerItem;
                        }),
                      };
                    }

                    return innerItem;
                  }),
                }))
              }
              size="l"
            />
          </div>

          <Select
            label="Группа дисциплин:"
            placeholder="5"
            className="shrink-0 small-label"
            labelPosition="left"
            items={Object.keys(disciplines).map((key) => ({
              label: key,
              value: key,
            }))}
            value={child.subject_group}
            onChange={(value) =>
              setValues((v: any) => ({
                ...v,
                marks: v.marks.map((innerItem: any) => {
                  if (innerItem.label === item.label) {
                    return {
                      ...innerItem,
                      marks: innerItem.marks.map((inInnerItem: any) => {
                        if (inInnerItem.id === child.id) {
                          return {
                            ...inInnerItem,
                            subject_group: value,
                          };
                        }

                        return inInnerItem;
                      }),
                    };
                  }

                  return innerItem;
                }),
              }))
            }
            size="l"
          />
        </div>
      )),
    }));
  const items = [
    {
      label: "Общая информация",
      content: (
        <div className="flex flex-col gap-8 max-w-[1524px]  w-full py-6 px-10">
          <Select
            label="Возраст:"
            placeholder="Введите свой возраст"
            className="shrink-0"
            value={values.years}
            labelPosition="left"
            items={Array.from({ length: 45 }, (_, i) => ({
              label: String(i + 16),
              id: String(i + 16),
            }))}
            size="l"
            onChange={(value) =>
              setValues((v: any) => ({ ...v, years: value }))
            }
          />

          <div className="flex gap-2 flex-wrap">
            <label className="text-[1.5rem] min-w-[480px]">
              Пол: <span className="FieldLabel-Star">*</span>
            </label>
            <RadioGroup
              value={values.sex}
              className="ml-2"
              name="sex"
              direction="row"
              size="l"
              items={[
                {
                  name: "Мужской",
                },
                {
                  name: "Женский",
                },
              ]}
              getItemLabel={(item: any) => item.name}
              getItemDisabled={(item) => item.disabled}
              onChange={(value) =>
                setValues((v: any) => ({ ...v, sex: value }))
              }
            />
          </div>

          <div className="flex gap-2 flex-wrap">
            <label className="text-[1.5rem] min-w-[480px]">Из России:</label>
            <RadioGroup
              value={values.fromRussia}
              className="ml-2"
              name="fromRussia"
              direction="row"
              size="l"
              items={[
                {
                  name: "Да",
                },
                {
                  name: "Нет",
                },
              ]}
              getItemLabel={(item: any) => item.name}
              getItemDisabled={(item) => item.disabled}
              onChange={(value) =>
                setValues((v: any) => ({ ...v, fromRussia: value }))
              }
            />
          </div>

          <TextField
            value={values.address || ""}
            onChange={(value) =>
              setValues((v: any) => ({ ...v, address: value }))
            }
            labelPosition="left"
            name="address"
            type="text"
            size="l"
            label="Адрес:"
            placeholder="Напишите адрес проживания в формате: Москва, Россия"
          />

          <div className="flex gap-2 flex-wrap">
            <label className="text-[1.5rem] min-w-[480px]">
              Военный билет:
            </label>
            <RadioGroup
              value={values.army}
              className="ml-2"
              name="army"
              direction="row"
              size="l"
              items={[
                {
                  name: "Да",
                },
                {
                  name: "Нет",
                },
              ]}
              getItemLabel={(item: any) => item.name}
              getItemDisabled={(item) => item.disabled}
              onChange={(value) =>
                setValues((v: any) => ({ ...v, army: value }))
              }
            />
          </div>

          <div className="flex gap-2 flex-wrap">
            <label className="text-[1.5rem] min-w-[480px]">
              Школа с уклоном:
            </label>
            <RadioGroup
              value={values.school}
              className="ml-2"
              name="school"
              direction="row"
              size="l"
              items={[
                {
                  name: "Да",
                },
                {
                  name: "Нет",
                },
              ]}
              getItemLabel={(item: any) => item.name}
              getItemDisabled={(item) => item.disabled}
              onChange={(value) =>
                setValues((v: any) => ({ ...v, school: value }))
              }
            />
          </div>

          <div className="flex gap-2 flex-wrap">
            <label className="text-[1.5rem] min-w-[480px]">Колледж:</label>
            <RadioGroup
              value={values.college}
              className="ml-2"
              name="college"
              direction="row"
              size="l"
              items={[
                {
                  name: "Да",
                },
                {
                  name: "Нет",
                },
              ]}
              getItemLabel={(item: any) => item.name}
              getItemDisabled={(item) => item.disabled}
              onChange={(value) =>
                setValues((v: any) => ({ ...v, college: value }))
              }
            />
          </div>

          <div className="flex gap-2 flex-wrap">
            <label className="text-[1.5rem] min-w-[480px]">
              Призер олимпиад:
            </label>
            <RadioGroup
              value={values.olimpic}
              className="ml-2"
              name="olimpic"
              direction="row"
              size="l"
              items={[
                {
                  name: "Да",
                },
                {
                  name: "Нет",
                },
              ]}
              getItemLabel={(item: any) => item.name}
              getItemDisabled={(item) => item.disabled}
              onChange={(value) =>
                setValues((v: any) => ({ ...v, olimpic: value }))
              }
            />
          </div>
        </div>
      ),
    },
    {
      label: "ЕГЭ",
      content: (
        <div className="flex flex-col gap-8 max-w-[1524px] max-lg:flex-col w-full py-6 px-10">
          {uniqueEge.map((item: any, i: number) => (
            <div
              className="flex lg:items-center flex-wrap lg:flex-nowrap max-lg:flex-col w-full justify-between gap-6"
              key={`${item.id}${i}`}
            >
              <Select
                label={`${i + 1}. Название предмета:`}
                className="shrink-0 flex-1"
                placeholder="Выберите предмет"
                labelPosition="left"
                value={{ label: item.subject, id: item.subject }}
                items={subjects}
                size="l"
                onChange={(value) =>
                  setValues((v: any) => ({
                    ...v,
                    ege: v.ege.map((innerItem: any) => {
                      if (innerItem.id === item.id) {
                        return { ...innerItem, subject: value?.label };
                      }

                      return innerItem;
                    }),
                  }))
                }
              />

              <Select
                label="Оценка:"
                placeholder="100"
                labelPosition="left"
                className="shrink-0 w-[200px] small-label"
                items={Array.from({ length: 100 }, (_, i) => ({
                  label: String(100 - i),
                  id: String(100 - i),
                }))}
                value={item.mark}
                onChange={(value) =>
                  setValues((v: any) => ({
                    ...v,
                    ege: v.ege.map((innerItem: any) => {
                      if (innerItem.id === item.id) {
                        return {
                          ...innerItem,
                          mark: value,
                        };
                      }

                      return innerItem;
                    }),
                  }))
                }
                size="l"
              />
            </div>
          ))}

          <Button
            label="Добавить"
            className="w-fit"
            onClick={() =>
              setValues((v: any) => ({
                ...v,
                ege: v.ege.concat({
                  id: idGenerator.next().value,
                  subject: "",
                  mark: "",
                }),
              }))
            }
          />
        </div>
      ),
    },
    {
      label: "ВУЗ",
      content: (
        <div className="flex flex-col gap-8 max-w-[1524px]  w-full py-6 px-10">
          <div className="flex gap-2 flex-wrap">
            <label className="text-[1.5rem] shrink-0 min-w-[480px]">
              Уровень образования:
            </label>
            <RadioGroup
              value={values.academicLevel}
              className="ml-2"
              name="academicLevel"
              direction="row"
              size="l"
              items={[
                {
                  name: "Бакалавр",
                },
                {
                  name: "Магистр",
                },
                {
                  name: "Специалитет",
                },
              ]}
              getItemLabel={(item: any) => item.name}
              getItemDisabled={(item) => item.disabled}
              onChange={(value) =>
                setValues((v: any) => ({ ...v, academicLevel: value }))
              }
            />
          </div>
          <div className="flex gap-2 flex-wrap">
            <label className="text-[1.5rem] shrink-0 min-w-[480px]">
              Форма обучения:
            </label>
            <RadioGroup
              value={values.studForm}
              className="ml-2"
              name="studForm"
              direction="row"
              size="l"
              items={[
                {
                  name: "Очная",
                },
                {
                  name: "Очно-заочная",
                },
                {
                  name: "Заочная",
                },
              ]}
              getItemLabel={(item: any) => item.name}
              getItemDisabled={(item) => item.disabled}
              onChange={(value) =>
                setValues((v: any) => ({ ...v, studForm: value }))
              }
            />
          </div>
          <div className="flex gap-2 flex-wrap">
            <label className="text-[1.5rem] shrink-0 min-w-[480px]">
              Льготы при поступлении:
            </label>
            <RadioGroup
              value={values.bonuses}
              className="ml-2"
              name="bonuses"
              direction="row"
              size="l"
              items={[
                {
                  name: "Да",
                },
                {
                  name: "Нет",
                },
              ]}
              getItemLabel={(item: any) => item.name}
              getItemDisabled={(item) => item.disabled}
              onChange={(value) =>
                setValues((v: any) => ({ ...v, bonuses: value }))
              }
            />
          </div>
          <div className="flex gap-2 flex-wrap">
            <label className="text-[1.5rem] shrink-0 min-w-[480px]">
              Наличие стипендии:
            </label>
            <RadioGroup
              value={values.money}
              className="ml-2"
              name="money"
              direction="row"
              size="l"
              items={[
                {
                  name: "Да",
                },
                {
                  name: "Нет",
                },
              ]}
              getItemLabel={(item: any) => item.name}
              getItemDisabled={(item) => item.disabled}
              onChange={(value) =>
                setValues((v: any) => ({ ...v, money: value }))
              }
            />
          </div>

          <DatePicker
            label="Дата зачисления:"
            name="firstDay"
            labelPosition="left"
            value={values.firstDay}
            onChange={(value) =>
              setValues((v: any) => ({ ...v, firstDay: value }))
            }
          />

          <DatePicker
            label="Дата начала обучения:"
            name="firstStudyDay"
            labelPosition="left"
            value={values.firstStudyDay}
            onChange={(value) =>
              setValues((v: any) => ({ ...v, firstStudyDay: value }))
            }
          />

          <Select
            name="studHome"
            label="Общежитие:"
            placeholder="Выберите подходящий вариант"
            labelPosition="left"
            value={values.studHome}
            items={[
              {
                label: "Жил в общежитии и проживаю в данный момент",
                id: 1,
              },
              {
                label: "Жил в общежитие, на данный момент не проживаю",
                id: 2,
              },
              {
                label: "Не жил в общежитии и не планирую проживать",
                id: 3,
              },
              {
                label: "Не жил в общежитии, планирую там проживать",
                id: 4,
              },
            ]}
            onChange={(value) =>
              setValues((v: any) => ({ ...v, studHome: value }))
            }
          />

          <Select
            name="practicesCount"
            label="Количество практик:"
            placeholder="0"
            value={values.practicesCount}
            labelPosition="left"
            items={Array.from({ length: 100 }, (_, i) => ({
              label: i,
              id: i,
            }))}
            onChange={(value) =>
              setValues((v: any) => ({ ...v, practicesCount: value }))
            }
          />

          <Select
            name="course"
            required
            label="Курс:"
            placeholder="Выберите подходящий вариант"
            value={values.course}
            labelPosition="left"
            items={Array.from({ length: 5 }, (_, i) => ({
              label: i + 1,
              id: i + 1,
            }))}
            onChange={(value) =>
              setValues((v: any) => ({ ...v, course: value }))
            }
          />

          <Select
            name="semester"
            required
            label="Полугодие:"
            placeholder="Выберите подходящий вариант"
            value={values.semester}
            labelPosition="left"
            items={Array.from({ length: 2 }, (_, i) => ({
              label: i + 1,
              id: i + 1,
            }))}
            onChange={(value) =>
              setValues((v: any) => ({ ...v, semester: value }))
            }
          />
        </div>
      ),
    },
    {
      label: "Оценки",
      content: (
        <div className="flex flex-col gap-8 max-w-[1524px]  w-full py-6 px-10">
          <CollapseGroup
            items={preparedMarks}
            getItemLabel={(item: any) => item.label}
            getItemContent={(item: any) => (
              <div className="flex flex-col gap-8">
                <Select
                  label="Количество долгов:"
                  placeholder="0"
                  className="shrink-0 w-full"
                  labelPosition="left"
                  items={Array.from({ length: 101 }, (_, i) => ({
                    label: String(i),
                    id: String(i),
                  }))}
                  value={item.dolg}
                  onChange={(value) =>
                    setValues((v: any) => ({
                      ...v,
                      marks: v.marks.map((innerItem: any) => {
                        if (innerItem.label === item.label) {
                          return {
                            ...innerItem,
                            dolg: value,
                          };
                        }

                        return innerItem;
                      }),
                    }))
                  }
                  size="l"
                />

                {item.marks}
                <Button
                  label="Добавить"
                  className="w-fit"
                  onClick={() =>
                    setValues((v: any) => ({
                      ...v,
                      marks: v.marks.map((mark: any) =>
                        mark.label === item.label
                          ? {
                              ...mark,
                              marks: mark.marks.concat({
                                id: idGenerator.next().value,
                                subject: "",
                                mark: "",
                              }),
                            }
                          : mark
                      ),
                    }))
                  }
                />
              </div>
            )}
            size="m"
          />
        </div>
      ),
    },
    {
      label: "Дополнительная информация",
      content: (
        <div className="flex flex-col gap-8 max-w-[1524px]  w-full py-6 px-10">
          <Select
            label="Сколько дисциплин в семестре:"
            placeholder="0"
            className="shrink-0 w-full"
            labelPosition="left"
            items={Array.from({ length: 30 }, (_, i) => ({
              label: String(i),
              id: String(i),
            }))}
            value={values.disciplinesCountFuture}
            onChange={(value) =>
              setValues((v: any) => ({
                ...v,
                disciplinesCountFuture: value,
              }))
            }
            size="l"
          />

          <Combobox
            label="Какие дисциплины нужно предсказать:"
            placeholder="Выберите нужные элементы из списка"
            className="shrink-0 w-full"
            labelPosition="left"
            multiple
            items={Object.keys(disciplines).map((key, i) => ({
              label: key,
              id: String(i),
            }))}
            value={values.disciplines}
            onChange={(value) =>
              setValues((v: any) => ({
                ...v,
                disciplines: value,
              }))
            }
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <form
        method="POST"
        className="form-section flex flex-col gap-6 lg:gap-10 py-12 lg:py-20 w-full"
        onSubmit={onSubmit}
      >
        <CollapseGroup
          items={items}
          size="l"
          style={{ "--graphics-size-s": "4rem !important" } as any}
        />

        <button type="submit" className="form-section-button">
          Узнать результат
        </button>
      </form>

      <AppLine />

      {result?.length > 0 && (
        <div className="result-section flex flex-col gap-6 lg:gap-10 py-12 lg:py-20 w-full">
          <div className="font-medium text-[1rem] md:text-[2rem] bg-bg-ternary text-white shadow-xl p-16 rounded-[2rem] bg-opacity-50 flex flex-col gap-8">
            <Table
              rows={result}
              columns={[
                {
                  title: "Группа дисциплин",
                  accessor: "id",
                },
                {
                  title: "Точность предсказания",
                  accessor: "percent",
                  width: 200,
                },
                {
                  title: "Оценка",
                  accessor: "mark",
                  width: 100,
                },
                {
                  title: "Точность предсказания (оптимистичной)",
                  accessor: "percentOptimistic",
                  width: 300,
                },
                {
                  title: "Оценка (оптимистичной)",
                  accessor: "markOptimistic",
                  width: 200,
                },
                {
                  title: "Точность предсказания (средней)",
                  accessor: "percentAVG",
                  width: 300,
                },
                {
                  title: "Оценка (средней)",
                  accessor: "markAVG",
                  width: 200,
                },
              ]}
            />
          </div>
        </div>
      )}
    </>
  );
};
