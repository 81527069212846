import React, { useEffect, useState } from "react";
import { FileField } from "@consta/uikit/FileField";
import { TextField } from "@consta/uikit/TextField";
import "./TrainForm.css";
import DropFileWrapper from "../../../components/DropFileWrapeer";

type Props = {};

export const TrainForm = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [modelName, setModelName] = useState("");
  const [email, setEmail] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setError("");
  }, [email, file, modelName]);

  useEffect(() => {
    if (!success) {
      return;
    }

    const timeout = setTimeout(() => {
      setSuccess(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [success]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!file) {
      setError(`Файл не задан`);
      return;
    }

    if (!modelName) {
      setError(`Название не задано`);
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("model_name", modelName);
    formData.append("email", email);
    formData.append("file", file);

    try {
      const response = await fetch(
        "https://students.dev.echo-company.ru/api/v1/submit-application/red-diploma",
        {
          method: "POST",
          headers: {
            accept: "application/json",
          },
          body: formData,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("Success:", data);
      setEmail("");
      setModelName("");
      setFile(null);
      setSuccess(true);
    } catch (error) {
      console.error("Error submitting form:", error);
      setError(`Ошибка при отправке форм: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      method="POST"
      className="train-section flex flex-col gap-6 lg:gap-10 py-12 lg:py-20 w-full"
      onSubmit={handleSubmit}
    >
      <TextField
        labelPosition="left"
        label="Введите название для собственной модели:"
        value={modelName || ""}
        onChange={(value) => setModelName(value || "")}
        placeholder="Новая"
      />
      <TextField
        labelPosition="left"
        label="Введите ваш email:"
        value={email || ""}
        onChange={(value) => setEmail(value || "")}
        placeholder="Ваша электронная почта"
      />
      <div className="flex">
        <label
          className="FieldLabel text-white text-[1.5rem] !shrink-[1] max-w-[550px]"
          htmlFor="file"
        >
          Ознакомьтесь со структурой шаблона, который нужно будет передать для
          дообучения модели:
        </label>

        <a
          href="https://students.dev.echo-company.ruapi/v1/predict-mass/red-diploma-from-csv/example-train"
          download="example-train.csv"
          className="train-section-button text-base text-center block"
          type="submit"
        >
          Скачать файл
        </a>
      </div>
      <div className="w-full flex ">
        <label
          className="FieldLabel text-white text-[1.5rem] !shrink-[1]"
          htmlFor="file"
        >
          Загрузите свой файл с информацией, согласно&nbsp;критериям шаблона:
        </label>
        <FileField
          id="file"
          className="w-full"
          accept=".csv,.xls,.xlsx"
          onChange={(e: any) => setFile(e?.target?.files?.[0])}
        >
          {(props) => (
            <DropFileWrapper
              onDrop={(file) => setFile(file[0])}
              accept=".csv,.xls,.xlsx"
            >
              <div
                {...props}
                className="w-full h-fit p-10 flex flex-col gap-10 text-center text-white bg-bg-ternary bg-opacity-50 rounded-[40px]"
              >
                {!loading && !file && (
                  <>
                    <strong className="font-semibold text-xl">
                      Нажмите или перенесите файлы сюда, для загрузки
                    </strong>
                    <span className="text-lg">
                      Максимальный размер файла 50Мб
                    </span>
                    <span className="text-lg">Форматы: .csv, .xlsx</span>
                  </>
                )}
                {!loading && file && (
                  <strong className="font-semibold text-xl">
                    Файл загружен
                  </strong>
                )}
                {loading && (
                  <p>Файл обрабатывается, пожалуйста, подождите...</p>
                )}
              </div>
            </DropFileWrapper>
          )}
        </FileField>
      </div>
      {success && (
        <p className="text-xl font-semibold text-primary w-full text-center">
          Успешно отправлено
        </p>
      )}

      {error && (
        <p className="text-base text-red-500 w-full text-center">{error}</p>
      )}
      <button className="train-section-button w-fit mx-auto" type="submit">
        Отправить заявку
      </button>
    </form>
  );
};
