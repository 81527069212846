import React, { useState } from "react";
import { FileField } from "@consta/uikit/FileField";
import { Table } from "@consta/uikit/Table";
import "./MultipleForm.css";
import { pluralRu } from "../../../lib/pluralRu";
import DropFileWrapper from "../../../components/DropFileWrapeer";

type Props = {};

export const MultipleForm = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<any>();

  const handleFileUpload = async (file: File) => {
    setLoading(true);
    setResults(undefined);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(
        "https://students.dev.echo-company.ru/api/v1/predict-mass/expulsion-from-file",
        {
          method: "POST",
          headers: {
            accept: "application/json",
          },
          body: formData,
        }
      );

      if (response.status !== 200) {
        return;
      }

      const data = await response.json();
      setResults(data);
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-6 lg:gap-10 py-12 lg:py-20 w-full">
      <div className="relative rounded-[2.5rem] overflow-hidden flex-1 shrink-0 z-0 text-white p-7 py-12 font-medium flex flex-col gap-12 bg-bg-quaternary">
        <img
          src="/green-ai-bg.jpg"
          className="absolute top-0 left-0 lg:left-[40%] z-[-1] h-auto w-full opacity-50 bg-black blur-[0.2rem]"
          alt="AI Molodca"
        />

        <h2 className="z-1 text-[4rem] leading-[4.01rem]">
          Перед загрузкой файла ознакомьтесь с нашим шаблоном, чтобы убедиться,
          что ваши данные соответствуют необходимым требованиям.
        </h2>
        <a
          className="multiple-form-button"
          href="https://students.dev.echo-company.ru/api/v1/predict-mass/expulsion-from-csv/example"
          download="example.csv"
        >
          Скачать файл
        </a>
      </div>

      <FileField
        id="file"
        accept=".csv,.xls,.xlsx"
        onChange={(e: any) => handleFileUpload(e?.target?.files?.[0])}
      >
        {(props) => (
          <DropFileWrapper
            onDrop={(file) => handleFileUpload(file[0])}
            accept=".csv,.xls,.xlsx"
          >
            <div
              {...props}
              className="w-full h-fit p-10 flex flex-col gap-10 text-center text-white bg-[#D0FFD2] bg-opacity-50 rounded-[40px]"
            >
              {!loading && (
                <>
                  {" "}
                  <strong className="font-semibold text-xl">
                    Нажмите или перенесите файлы сюда, для загрузки
                  </strong>
                  <span className="text-lg">
                    Максимальный размер файла 50Мб
                  </span>
                  <span className="text-lg">Форматы: .csv, .xlsx</span>
                </>
              )}

              {loading && <p>Файл обрабатывается, пожалуйста, подождите...</p>}
            </div>
          </DropFileWrapper>
        )}
      </FileField>

      {results && (
        <div className="result-section flex flex-col gap-6 lg:gap-10 py-12 lg:py-20 w-full">
          <div className="font-medium text-[1rem] md:text-[2rem] bg-bg-ternary text-white shadow-xl p-16 rounded-[2rem] bg-opacity-50 flex flex-col gap-8">
            <h2 className="text-3xl">
              Документ содержит {results?.successful_count}{" "}
              {pluralRu(results?.successful_count, [
                "строка",
                "строки",
                "строчек",
              ])}
              , в качестве примера, ознакомьтесь с некоторыми из них:
            </h2>
            <Table
              className="mt-7"
              rows={results?.first_five_successful}
              columns={[
                {
                  title: "Студент",
                  accessor: "id",
                },
                {
                  title: "Точность предсказания",
                  accessor: "Точность",
                  width: 200,
                  renderCell: (row) => `${row["Точность"].toFixed(2) || 0}%`,
                },
                {
                  title: "Вероятность",
                  accessor: "Отчисление",
                  renderCell: (row) => row["Отчисление"] || 0,
                },
              ]}
            />
            {results?.first_five_successful.length > 5 && (
              <>
                <p>..............</p>
              </>
            )}

            <p className="text-lg">
              Чтобы увидеть полный результат, скачайте файл.
            </p>
            <a
              className="multiple-form-button text-lg"
              href={`https://students.dev.echo-company.ru${results.file_url}`}
              download="result.zip"
            >
              Скачать документ с оценками
            </a>
          </div>
        </div>
      )}
    </div>
  );
};
